header {
    .image {
        background-image: url(/img/bg-smoke.jpg);
        background-size: cover;
        .overlay {
            background-color: rgba($saddle-brown, .45);
            .content {
                color: $rosy-brown-light;
                font-size: 6rem;
                .menu {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    margin-top: 35px;
                    .item {
                        flex: 1 1 33%;
                        a {
                            color: $rosy-brown-light;
                            font-size: 25px;
                            padding: 20px;
                            display: block;
                            background-color: rgba($peru, .45);
                            margin: 20px;
                            box-sizing: content-box;
                            border-radius: 5px;
                            transition: all .5s;
                            @include states {
                                color: $rosy-brown-light;
                                background-color: rgba($peru, .9);
                            }
                        }
                    }
                }
            }
        }
    }
}