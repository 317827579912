$saddle-brown: #240F07;
$peru: #D88938;
$saddle-brown-light: #6D3C1A;
$rosy-brown: #B68F7A;
$rosy-brown-light: #CDBAAC;

@import url('https://fonts.googleapis.com/css?family=Kodchasan');

body {
    font-family: 'Kodchasan', sans-serif;
}